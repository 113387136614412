.propertiesPages {
  display: flex;
  flex-direction: column;
  height: 250vh;
  width: 100vw;
  justify-content: center;
  scroll-snap-align: start;
  background-color: rgb(255, 255, 255);
  background-position: center;
  background-size: cover;
}

.propertyPage1 {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  scroll-snap-align: start;
  background-image: url("./properties_image1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bodyPage1 {
  height: 20%;
  width: 100%;
  margin: auto;
  text-align: center;
  color: white;
}

.titlePage1 {
  height: 80%;
  width: 100%;
  font-size: 50px;
}

.galleryPage {
  display: flex;
  flex-direction: row;
  height: 140vh;
  margin: auto;
  overflow-y: hidden;
}

.galleryPage > div {
  margin: 8px;
}

.galleryContainerLeft {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 650px;
  justify-content: space-between;
}

.galleryContainerRight {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 650px;
  justify-content: space-between;
}

.homeImg1,
.homeImg2,
.homeImg3,
.homeImg4,
.homeImg5,
.homeImg6 {
  height: 400px;
  padding-bottom: 0.5em;
}

@media only screen and (max-width: 1290px) {
  .propertyPage1 {
    flex-direction: column;
    height: 70%;
  }
  .titlePage1 {
    font-size: 40px;
    height: auto;
    width: 100%;
  }
}

@media only screen and (max-width: 1290px) {
  .galleryPage {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .galleryPage > div {
    margin: 0;
  }
  .galleryContainerLeft,
  .galleryContainerRight {
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 400px;
    margin: auto;
  }
  .homeImg1,
  .homeImg2,
  .homeImg3,
  .homeImg4,
  .homeImg5,
  .homeImg6 {
    height: 500px;
    padding-bottom: 0;
    border-top: solid 1px white;
  }
  .homeImg6 {
    border-bottom: 1px solid white;
  }
}
