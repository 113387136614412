.home {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  scroll-snap-align: start;
  background-image: url("./homepage1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.jmlogo {
  width: 70%;
  margin: auto;
  overflow: auto;
}
@media only screen and (max-width: 1290px) {
  .home {
    flex-direction: column;
  }
}
