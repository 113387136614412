.contactPage {
  display: flex;
  flex-direction: column;
  height: 150vh;
  width: 100vw;
  justify-content: center;
  scroll-snap-align: start;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(255, 255, 255);
}

.contactBody {
  margin: auto;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  justify-content: space-between;
}

.contactMeTextContainer {
  height: 400px;
  width: 450px;
  text-align: center;
  margin: auto;
  font-style: bold;
  color: rgb(0, 0, 0);
}

.contactMeHeading {
  font-size: 60px;
}

.contactMeText1,
.contactMeText2,
.contactMeText3 {
  padding-bottom: 0.5em;
  font-size: 30px;
}

.instagram,
.linkedin,
.youtube {
  margin: 2px;
}

.submitFormContainer {
  width: 30vw;
  text-align: center;
  margin: auto;
}

.submitFormContainer > div {
  margin: 8px;
}

.submitNameContainer,
.submitEmailContainer,
.submitMessageContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-bottom: 0.5em;
  font-size: 40px;
}

.nameLabel,
.emailLabel,
.messagesLabel {
  padding-bottom: 0.5em;
}

.nameBox,
.emailBox,
.messagesBox {
  display: block;
  width: 90%;
  padding: 10px 10px 10px;
  border: none;
  border-bottom: 1px solid;
  font-size: 18px;
}

.submitButtonContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.submitBtn {
  display: inline-block;
  text-align: center;
  padding: 15px 32px;
  border: none;
  text-decoration: none;
  font-size: 16px;
  background-color: #000000;
  color: white;
}

.contactFooterContainer {
  height: 30vh;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.contactFooterCenter {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.attlasLogo {
  height: 100px;
  width: 300px;
  margin: auto;
}

.footerBre,
.footerDre {
  font-size: 30px;
  text-align: center;
  margin: auto;
  padding: auto;
  color: black;
}

@media only screen and (max-width: 1290px) {
  .contactBody {
    display: flex;
    flex-direction: column;
    height: 80vh;
  }

  .contactMeTextContainer {
    width: 100vw;

    height: 185px;
  }
  .contactMeHeading {
    font-size: 36px;
    margin: auto;
  }
  .contactMeText1,
  .contactMeText2,
  .contactMeText3 {
    font-size: 18px;
  }

  .submitFormContainer {
    width: 80%;
    height: 400px;
  }

  label {
    font-size: 16px;
  }

  .contactFooterContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .attlasLogo {
    height: 60px;
    width: auto;
  }
  .footerBre,
  .footerDre {
    font-size: 18px;
  }
}

@media only screen and (max-height: 694px) {
  .footerBre,
  .footerDre {
    font-size: 14px;
  }
  .attlasLogo {
    width: 90vw;
  }
}
