.aboutPage {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(255, 255, 255);
}

.aboutHeader {
  color: rgb(0, 0, 0);
  width: 100%;
  margin: 6em auto 20px auto;
  text-align: center;
  height: 126px;
  padding: 30px;
  overflow: hidden;
}
.aboutTitle {
  padding-bottom: 0.5em;
  font-size: 42px;
  margin: auto;
}

.caption {
  margin: auto;
  font-style: italic;
  font-size: 24px;
}

.aboutMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  padding: 40px;
  height: 80%;
}

.textContainer {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: auto;
  height: 94%;
}

.pgph1 {
  font-size: 18px;
  color: black;
  width: 90%;
  margin: auto;
  overflow-y: hidden;
}

.pgph2 {
  font-size: 18px;
  color: black;
  width: 90%;
  margin: auto;
  overflow-y: hidden;
}

.photoContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 94%;
  width: 40%;
}
.photo {
  height: 400px;
  width: 400px;
  margin: auto;
}

@media only screen and (max-width: 1090px) {
  .aboutHeader {
    flex-direction: row;
    height: 50px;
    padding: 0;
  }

  .aboutTitle {
    font-size: 18px;
    padding: 0;
  }
  .caption {
    font-size: 12px;
    padding: 0;
  }
  .aboutPage {
    flex-direction: column;
  }
  .aboutMain {
    flex-direction: column;
    width: 100%;
    height: 98vh;
    margin: auto;
    padding: 4px;
  }
  .pgph1,
  .pgph2,
  .pgph3 {
    font-size: 16px;
    margin: auto;
    padding: 4px;
    overflow-y: hidden;
  }
  .photoContainer {
    padding: 0.5em 0;
    height: 302px;
    width: 100%;
  }
  .photo {
    height: 204px !important;
    width: 204px !important;
  }

  .textContainer {
    width: 100%;
    margin: auto;
    height: 100%;
  }
}
