.header {
  height: 70px;
  margin: auto;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  background-color: rgba(132, 132, 132, 0.361);
}

.header_center {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  color: white;
  width: 50%;
  text-decoration: underline;
}

.headerlink {
  color: white;
  cursor: pointer;
}

.headerlink:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1290px) {
  .header_center {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
}
